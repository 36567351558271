// essence_milano

var essence_milano = window.essence_milano || {};

(function($) {

//scroll to anchor
  essence_milano.scrollto = function(){
    $('a.scrollto').bind('click', function(e) {
      var $anchor = $(this);
      if ($anchor.attr('data-offset')) { offset = $anchor.attr('data-offset') } else { offset = 0;}
      $('html, body').stop().animate({ scrollTop: ( $($anchor.attr('href')).offset().top ) - offset }, 600);
      e.preventDefault();
    });
  };

//parallax
  essence_milano.parallax = function() {

    $( window ).load(function() {

      $.stellar.positionProperty.essence_milano = {
        setPosition: function($element, newLeft, originalLeft, newTop, originalTop) {
          if( ($element).hasClass("fromleft") ) {
            newtransform = (-(newTop - originalTop));
            if (newtransform>0) { newtransform = 0; }
            $element.css('transform', 'translate3d(' + newtransform + 'px, 0, 0)');
          } else if( ($element).hasClass("fromright") ) {
            newtransform = (newTop - originalTop);
            if (newtransform<0) { newtransform = 0; }
            $element.css('transform', 'translate3d(' + newtransform + 'px, 0, 0)');
          } else {
            $element.css("transform", "translate3d(0 , " + (newTop - originalTop) + "px,0)")
          }
        }
      }, $.stellar({
        verticalOffset: 0,
        horizontalScrolling: !1,
        positionProperty: "essence_milano",
        hideDistantElements: !1,
        responsive: true
      })
      setTimeout(function(){ $.stellar('refresh'); },1000)

      //appear on scroll
      wow = new WOW({
          boxClass: 'scrollshow',
          animateClass: 'animate',
          offset: 50,
          mobile: true,
          live: true
      });
      wow.init();

      //$(".photos a").click(function(e){
        //e.preventDefault();
      //})
      //custom script for gallery mobile
      $(window).scroll(function () {
        if($(window).width()<769) {
          scroll = $(window).scrollTop();
          height = $(window).height()/4;
          $(".photos a").each(function(){
            photoscroll = $(this).offset();
            if( $(this).hasClass("scrolled") ) {
              if((scroll < (photoscroll.top-height))||(scroll > (photoscroll.top+height))) { $(this).removeClass("scrolled"); }
            } else {
              if((scroll > (photoscroll.top-height))&&(scroll < (photoscroll.top+height))) { $(this).addClass("scrolled"); }
            }
          })
        }
      });

    });

  }
  
//video modal  
  essence_milano.video_modal = function() {
      var url = $("#videoyt").attr('src');
    $("#videoyt").attr('src', '');
    $("#videomodal").on('shown.bs.modal', function(){
        $("#videoyt").attr('src', url);
    });
    $("#videomodal").on('hide.bs.modal', function(){
        $("#videoyt").attr('src', '');
    });
  }
  
  
  
//form validation  
  essence_milano.form = function() {
  	var isTouchDevice = 'ontouchstart' in document.documentElement;
  	if( isTouchDevice ) {
  		$('body').addClass('touch');
  	}
    
    $.extend( $.validator.messages, {
			required: "Campo obbligatorio",
			remote: "Controlla questo campo",
			email: "Inserisci un indirizzo email valido",
			url: "Inserisci un indirizzo web valido",
			date: "Inserisci una data valida",
			dateISO: "Inserisci una data valida (ISO)",
			number: "Inserisci un numero valido",
			digits: "Inserisci solo numeri",
			creditcard: "Inserisci un numero di carta di credito valido",
			equalTo: "Il valore non corrisponde",
		});
     
    $("#applyform").validate({
      errorElement: 'span',
      invalidHandler: function(event, validator) {
        var errors = validator.numberOfInvalids();
        if (errors) {
          $(".error-maxsize").removeClass("error-maxsize");
        } else {
        }
      },
      submitHandler: function(form) {
        //form.submit();
        $("#submit").addClass("loading");
        var postfield = $("#applyform").serializeArray();
				if (!location.origin) { location.origin = location.protocol + "//" + location.host; }
				$.post(location.origin + "/wp-content/themes/essence_landing/lib/send.php", postfield, function(data) {
					if(data) {
						$('#formmodal .success').show();
		        $('#formmodal .fail').hide();
						$("#submit").removeClass("loading");
		        $("label > span").html("");
		        $("#applyform").addClass("sended");
		        $('#formmodal').modal('show');
		        var label = $("#landing").val();
		        var tracker = ga.getAll()[0].get('name');
		        ga(tracker+'.send', 'event', 'form', 'invio', label, 1);
		        console.log("TRACK - " + label);
		        Intercom("trackEvent", "form_send");
		        //$('#formmodal .success').append('<iframe style="display:none" src="'+location.origin + bodysubdir + '/thankyou.html"></iframe>');
					} else {
						$('#formmodal .fail').show();
		        $('#formmodal .success').hide();
						$("#submit").removeClass("loading");
						$('#formmodal').modal('show');
					}
				});
        
      }
    });    
  }
  
  

//slider  
  essence_milano.slider = function() {

		
		
	}


//init
  essence_milano.init = function(){
    essence_milano.scrollto();
    essence_milano.parallax();
    essence_milano.slider();
    essence_milano.video_modal();
    essence_milano.form();
  }

  essence_milano.init();

})(jQuery);
